<template>
    <div>
        <s-form @submit="addItem">
            <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Semen Receipt Storage Add</h2>
            <div class="row mt-4">
                <div class="col-lg-2">
                    <validated-ajax-vue-select :url="regNoOptions" label="Reg No." v-model="model.receipt"  @input="loadDetails" class="c-input-select text-black field-required" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select :url="breedOptions" label="Breed" v-model="model.breed" class="c-input-select text-black" disabled></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select :url="doseTypeOptions" label="Dose Type" v-model="model.dose_type" class="c-input-select text-black" disabled></validated-ajax-vue-select>
                </div>
<!--                <div class="col-lg-2">-->
<!--                    <validated-input label="Location" v-model="model.location" class="text-black field-required" :rules="{required:true}"></validated-input>-->
<!--                </div>-->
<!--                <div class="col-lg-2">-->
<!--                    <validated-input label="Goblet Type" v-model="model.goblet_type" class="text-black"></validated-input>-->
<!--                </div>-->
                <div class="col-lg-2">
                    <validated-input label="No. of Doses" v-model="model.no_of_doses" class="text-black" disabled></validated-input>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-2">
                     <validated-vue-select @input="FSDStorageOptions" :rules="{required:true}"
                                              :options="storageTypeOptions"
                                              label="Storage Type" class="c-input-select text-black field-required" v-model="model.storage_type">

                        </validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select :rules="{required:true}" class="c-input-select text-black field-required"
                                              :options="FSDStorageNameOptions" label="Storage Name"
                                          v-model="model.storage_name"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                         <validated-input :rules="{required:true}"  class="text-black field-required"
                                         label="Location" v-model="FSDLocation">

                        </validated-input>
                </div>
                <div class="col-lg-2">
                     <validated-ajax-vue-select :rules="{required:true}" class="c-input-select text-black field-required"
                                                :url="positionOptions" label="Goblet Type" v-model="model.storage_position"></validated-ajax-vue-select>

                </div>

            </div>
            <div class="row mt-lg-9">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
// import CustomSingleColumnTable from '../../../components/ui/CustomSingleColumTable';
import urls from '../../../data/urls';
import axios from 'secure-axios';
import masterURLs from '../../../data/masterURLs';

export default {
    name: 'SemenReceiptStorageAdd',
    // components: { CustomSingleColumnTable },
    data () {
        return {
            loading: false,
            URL: urls.semen_storage.addEdit,
            model: {
                receipt: '',
                breed: '',
                dose_type: '',
                location: '',
                goblet_type: '',
                no_of_doses: '',
                storage_type: '',
                storage_name: '',
                storage_location: '',
                storage_position: ''

            },
            storageTypeOptions: [
                { label: 'JUMBO', value: 'JUMBO' },
                { label: 'OMR', value: 'OMR' }
            ],
            regNoOptions: urls.semen_receipt.vueSelect,
            breedOptions: masterURLs.master.breed.vueSelect,
            doseTypeOptions: masterURLs.master.doseType.vueSelect,
            positionOptions: masterURLs.master.quarantinePosition.vueSelect,
            FSDStorageNameOptions: [],
            storageNameOptionsURL: masterURLs.master.storageType.vueSelect,
            locationOptions: masterURLs.master.quarantineLocation.vueSelect
        };
    },
    computed: {
        FSDLocation: {
            get () {
                return this.model.storage_location.toUpperCase();
            },
            set (value) {
                this.model.storage_location = value.toUpperCase();
            }
        }
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/general/' });
        },
        async FSDStorageOptions () {
            const response = await axios.get(this.storageNameOptionsURL + '?storage_type=' + this.model.storage_type);
            this.FSDStorageNameOptions = response.data.data;
        },
        async loadDetails () {
            this.model.breed = '';
            this.model.no_of_doses = '';
            this.model.dose_type = '';
            const response = await axios.form(urls.semen_receipt.details, this.model);
            const data = response.data.data;
            this.model.breed = data.breed.id;
            this.model.no_of_doses = data.no_of_doses;
            this.model.dose_type = data.dose_type.id;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/general/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
</style>
